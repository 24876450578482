@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	p {
		@apply font-custom
	}
	.field {
		@apply bg-black10 py-1 px-2 border-2 border-white40 border-opacity-100 rounded-lg focus:outline-none focus:ring text-white100;
	}
}

html {
	background-color: white;
	font-family: Barlow;
}

h1 {
	font-size: 1.937rem;
	font-weight: 700;
}
• h2 {
	font-size: 1.375rem;
	font-weight: 700;
}
• h3 {
	font-size: 1rem;
}
• h4 {
	font-size: 0.75rem;
}
• p,
a {
	font-size: 0.875rem;
}

select {
	background: none;
}

@keyframes pulse-outline {
	0% {
		outline-color: #B95C28;
		outline-width: 0px;
	}

	100% {
		outline-color: transparent;
		outline-width: 15px;
	}
}

#playBtn,
#playBtnPrompt,
#playBtnMob,
#playBtnPromptMob {
	transition: opacity 0.6s ease-out;
}

.pulse {
	outline: solid;
	transform: translateX(-50%) translateY(-50%) translateZ(0);
	animation: pulse-outline 1.5s ease-out infinite;
}