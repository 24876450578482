.swal-button {
    padding: 7px 26px;
    border-radius: 50px;
    background-color: #36854C;
    font-size: 12px;
    border: 1px solid #36854C;
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
  }
.swal-button:hover {
    background-color: #36854C !important;
  }
  .swal-footer{
    display: flex;
    justify-content: center;
  }
  .swal-text{
    margin-left: 14px;
  }