.loader {
    --path: #2c703f;
    --dot: #B95C28;
    --duration: 3s;
    width: 44px;
    height: 44px;
    position: relative;

    &:before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        display: block;
        background: var(--dot);
        top: 37px;
        left: 19px;
        transform: translate(-18px, -18px);
        animation: dot var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    }

    svg {
        display: block;
        width: 100%;
        height: 100%;

        circle {
            fill: none;
            stroke: var(--path);
            stroke-width: 10px;
            stroke-linejoin: round;
            stroke-linecap: round;
            stroke-dasharray: (200 / 4 * 3) (200 / 4) (200 / 4 * 3) (200 / 4);
            stroke-dashoffset: 275;
            animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
        }
    }
}

@keyframes dot {
    25% {
        transform: translate(0, -36px);
    }

    50% {
        transform: translate(18px, -18px);
    }

    75% {
        transform: translate(0, 0px);
    }

    100% {
        transform: translate(-18, 0);
    }
}

@keyframes pathCircle {
    25% {
        stroke-dashoffset: 225;
    }

    50% {
        stroke-dashoffset: 175;
    }

    75% {
        stroke-dashoffset: 125;
    }

    100% {
        stroke-dashoffset: 75;
    }
}

.loader {
    display: inline-block;
    margin: 0 16px;
}